import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Вкусный Уголок – Школа Онлайн Кулинарии
			</title>
			<meta name={"description"} content={"Открой для себя мир кулинарии в один клик"} />
			<meta property={"og:title"} content={"Home | Вкусный Уголок – Школа Онлайн Кулинарии"} />
			<meta property={"og:description"} content={"Открой для себя мир кулинарии в один клик"} />
			<meta property={"og:image"} content={"https://vurbola.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vurbola.com/img/4762311.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vurbola.com/img/4762311.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vurbola.com/img/4762311.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vurbola.com/img/4762311.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vurbola.com/img/4762311.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vurbola.com/img/4762311.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Description-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-align-items="center"
				md-width="70%"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				sm-margin="30px 0px 30px 0px"
				sm-width="100%"
				width="75%"
				lg-width="80%"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				justify-content="space-between"
				sm-justify-content="center"
			>
				<Text
					margin="0px 0px 8px 0px"
					color="--grey"
					font="--base"
					lg-text-align="center"
					text-transform="uppercase"
					letter-spacing="2px"
				>
					Школа Онлайн Кулинарии
				</Text>
				<Text
					lg-text-align="left"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-font="normal 700 35px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-text-align="center"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline1"
				>
					Вкусный Уголок
				</Text>
			</Box>
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="100%"
				lg-width="100%"
			>
				<Box
					position="relative"
					padding="0px 0px 800px 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
				>
					<Image
						width="100%"
						right={0}
						src="https://vurbola.com/img/1.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						bottom="0px"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Почему "Вкусный Уголок"?
				</Text>
				<Text
					as="h1"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Мы рады предложить вам уникальный опыт изучения кулинарного искусства в уютной обстановке вашего дома. Наша школа онлайн-кулинарии создана для тех, кто хочет улучшить свои кулинарные навыки, исследовать новые вкусы и блюда со всего мира, не выходя из дома.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px">
					Индивидуальный подход
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Наши курсы адаптированы под любой уровень подготовки, от новичка до продвинутого кулинара.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Живые мастер-классы
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Наслаждайтесь взаимодействием с профессиональными поварами в реальном времени.
					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text font="--headline3" color="--dark" margin="0px 0px 6px 0px" sm-text-align="left">
					Доступность
					</Text>
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Все, что вам нужно, это интернет-соединение. Учебные материалы доступны в любое время.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});